import { FC, Fragment, useEffect } from 'react';
import MessageTemplateForm from '../../../components/MessageTemplates/MessageTemplateForm';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { useApi } from '../../../hooks/useApi';
import useToaster from '../../../hooks/useToaster';
import { AdminAppRoutes } from '../../../AdminApp';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { get } from 'lodash';
import { MessageTemplate } from '../../../types/resources';
import Loader from 'react-loader-spinner';
import { theme } from '../../../theme';
import { ConfirmOptions, useConfirm } from '../../../hooks/useConfirm';
import { Flex } from '../../../components/Layout';
import { IconButton } from '../../../components/Buttons';

interface MessageTemplateEditProps {
  id?: string;
  onGoBack?: () => void;
  onRefetch?: () => void;
  onSelect?: (content: string) => void;
}

const MessageTemplateEdit: FC<MessageTemplateEditProps> = (props) => {
  const { success, error } = useToaster();
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const templateId = get(match, 'params.templateId', '');
  const { id, onGoBack, onRefetch, onSelect } = props;

  const options: ConfirmOptions = {
    title: t('message_template.delete.modal.title'),
    description: t('message_template.delete.modal.description'),
    confirmLabel: t('message_template.delete.modal.confirm'),
    cancelLabel: t('message_template.delete.modal.cancel'),
  };

  const { confirm, ConfirmationDialog, isLoading } = useConfirm(async (data) =>
    deleteMessageTemplate({
      onSuccess: () => {
        success(t('message_template.delete.success'));
        history.push(AdminAppRoutes.MESSAGE_TEMPLATES);
      },
      onError: () => {
        error(t('message_template.delete.error'));
      },
    }),
  );

  const { execute: getMessageTemplate, state: getMessageTemplateState } =
    useApi<MessageTemplate>(`/conversations/models/${props.id || templateId}`, {
      method: 'GET',
    });

  const { execute: deleteMessageTemplate } = useApi(
    `/conversations/models/${props.id || templateId}`,
    {
      method: 'DELETE',
    },
  );

  const {
    execute: editMessageTemplate,
    state: { loading },
  } = useApi(`/conversations/models/${props.id || templateId}`, {
    method: 'PATCH',
  });

  useEffect(() => {
    getMessageTemplate();
  }, []);

  return (
    <>
      <div>
        {onGoBack ? (
          <Flex alignItems="center" marginBottom={{ xs: 'space24' }}>
            <Flex marginRight={{ xs: 'space16' }}>
              <IconButton
                iconName="ArrowLeft"
                color={theme.colors.white}
                backgroundColor={theme.colors.green1}
                hoverIconColor={theme.colors.green1}
                hoverBackgroundColor={theme.colors.green4}
                size="small"
                onClick={() => onGoBack()}
                rounded
              />
            </Flex>
          </Flex>
        ) : (
          <Breadcrumbs />
        )}
        {getMessageTemplateState.data?.value ? (
          <MessageTemplateForm
            isModal
            messageTemplate={getMessageTemplateState?.data?.value}
            loading={loading}
            onSubmit={(data) =>
              editMessageTemplate({
                body: data,
                onSuccess: () => {
                  success(t('message_template.edit.success'));

                  if (!id) {
                    history.push(AdminAppRoutes.MESSAGE_TEMPLATES);
                  } else {
                    onRefetch?.();
                  }
                },
                onError: () => {
                  error(t('message_template.edit.error'));
                },
              })
            }
            onDelete={async () => await confirm(options)}
            onSelect={onSelect}
          />
        ) : (
          <Loader
            type="TailSpin"
            height={24}
            width={24}
            color={theme.colors.salmon1}
          />
        )}
      </div>
      <ConfirmationDialog />
    </>
  );
};

export default MessageTemplateEdit;
