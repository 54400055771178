import { FC, forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';
import { Text } from 'components/Layout/Text';
import RequiredField from '../../Forms/Utils/RequiredField';
import { Flex } from 'components/Layout';
import { Label } from 'components/Text/Label';
import { FieldError } from 'react-hook-form';
import { determineIfIsFieldErrorOrString } from 'utils/errors';

const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
`;

const TextAreaComponent = styled.textarea<
  InputHTMLAttributes<HTMLTextAreaElement>
>`
  width: ${(props) => (props.width ? props.width : 'initial')};
  min-height: calc(${theme.spacing.space80} + ${theme.spacing.space40});
  border: 1px solid ${theme.colors.gray3};
  border-radius: ${theme.spacing.space16};
  font-size: 16px;
  color: ${theme.colors.black};
  font-weight: normal;
  padding: ${theme.spacing.space24};

  &:active,
  &:focus {
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;

  &:enabled {
    &:active,
    &:focus {
      border: 1px solid ${theme.colors.gray4};
      outline: none;
    }
  }
`;

export interface TextAreaProps
  extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: FieldError | string;
  rows?: number;
}

export const TextArea: FC<TextAreaProps> = forwardRef(
  (props: TextAreaProps, ref) => {
    const { label, error, rows, ...rest } = props;
    return (
      <TextAreaContainer>
        {label && (
          <Flex marginBottom={{ xs: 'space8' }}>
            <RequiredField required={props.required}>
              <Label dangerouslySetInnerHTML={{ __html: label }} />
            </RequiredField>
          </Flex>
        )}
        <TextAreaComponent rows={rows} ref={ref as any} {...rest} />
        {error && (
          <Text
            content={
              error && determineIfIsFieldErrorOrString(error)
                ? error.message
                : error
            }
            fontStyle="body2"
            color={theme.colors.red1}
            marginTop={{ xs: 'space8' }}
          />
        )}
      </TextAreaContainer>
    );
  },
);

TextArea.displayName = 'TextArea';
