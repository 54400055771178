import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InputText } from '../FormTemplate/Fields/InputText';
import { useTranslation } from 'react-i18next';
import { TextArea } from '../FormTemplate/Fields/TextArea';
import { Button } from '../Buttons';
import { MessageTemplate } from '../../types/resources';

type MessageTemplateData = {
  title: string;
  content: string;
};

interface MessageTemplateFormProps {
  loading: boolean;
  onSubmit: (data: MessageTemplateData) => void;
  onDelete?: () => void;
  messageTemplate?: MessageTemplate;
  isModal?: boolean;
  onSelect?: (content: string) => void;
}

const MessageTemplateForm: FC<MessageTemplateFormProps> = (props) => {
  const { t } = useTranslation();
  const { loading, onSubmit, messageTemplate, onDelete, isModal, onSelect } =
    props;

  const {
    control,
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<MessageTemplateData>({
    defaultValues: {
      title: messageTemplate?.title,
      content: messageTemplate?.content,
    },
  });

  const onSubmitHandler: SubmitHandler<MessageTemplateData> = (data) => {
    onSubmit(data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      className="tw-flex tw-flex-col tw-gap-8 tw-relative"
    >
      {isModal && (
        <div className="tw-flex tw-items-start tw-gap-4 tw-absolute -tw-top-16 tw-right-0">
          <Button
            iconLeft={{ name: 'Plus' }}
            content={t('add')}
            type="button"
            onClick={() => onSelect?.(watch('content'))}
            primary
          />
          <Button
            iconLeft={{ name: 'Edit' }}
            content={t('modify')}
            type="submit"
          />
        </div>
      )}
      <InputText
        {...register('title', {
          required: t('error.form.required').toString(),
        })}
        error={errors.title}
        label={t('message_template.form.title.label')}
        placeholder={t('message_template.form.title.placeholder')}
        width="100%"
      />
      <TextArea
        {...register('content', {
          required: t('error.form.required').toString(),
        })}
        error={errors.content}
        label={t('message_template.form.content.label')}
        placeholder={t('message_template.form.content.label')}
        width="100%"
        rows={10}
      />
      {!isModal &&
        (messageTemplate ? (
          <div className="tw-flex tw-items-start tw-gap-4">
            <Button
              content={t('message_template.edit.submit')}
              type="submit"
              $loading={loading}
              primary
            />
            <Button
              content={t('message_template.edit.delete')}
              onClick={onDelete}
              type="button"
              $loading={loading}
            />
          </div>
        ) : (
          <div className="tw-flex tw-items-start tw-gap-4">
            <Button
              content={t('message_template.add.submit')}
              type="submit"
              $loading={loading}
              primary
            />
          </div>
        ))}
    </form>
  );
};

export default MessageTemplateForm;
